import { Link } from 'gatsby';
import React from 'react';

export default function NavLink({
    children,
    k,
    href,
    classes = "text-base font-light text-gray-800 hover:text-gray-500 font-heading",
    onClick
}) {


    const isHome = href === "";
    const isKontakt = href === "kontakt";

    console.log("href", href);
    console.log("isKontakt", isKontakt);
    console.log("isHome", isHome);

    return (
        <Link
            to={`/${href}`}
            activeClassName={(!isKontakt && !isHome) ? `underline underline-offset-8` : ''}
            // partiallyActive={true}
            // activeStyle={{ color: "red" }}
            key={k}
            className={`${classes}`}
            onClick={onClick}
        >
            {children}
        </Link>

    )


}