import React, { useContext } from "react";
import { XIcon } from '@heroicons/react/solid'
import Logo from "./Logo";
import { Transition } from '@headlessui/react'
import { GlobalContext } from '../context/GlobalState';

function TextContainer({ children }) {
    return (
        <div className="relative w-4/5 text-2xl font-light text-justify hyphens-auto text-white">
            {children}
        </div>
    );
}


export default function ItemOverlay({ }) {

    const { setSelectedItem, selectedItem } = useContext(GlobalContext);

    const onCloseClick = () => {
        setSelectedItem(null);
    }

    if (selectedItem) {
        const { title, image, desc, bgColor, lead } = selectedItem;
        return (
            <Transition
                show={Boolean(selectedItem)}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`fixed inset-0 z-20 overflow-y-scroll ${bgColor}`}>
                    <div className="relative flex justify-between items-center px-4 py-6 sm:px-6 md:justify-between md:space-x-10">
                        <Logo />
                        <XIcon className="flex justify-end w-10 h-10 text-white cursor-pointer" onClick={onCloseClick} />
                    </div>
                    <div className="max-w-6xl">
                        <div className="px-4 py-6 sm:px-6 ">
                            <h2
                                className="block text-4xl  tracking-tight pb-4 text-white"
                            >
                                {title}
                            </h2>
                            <TextContainer>
                                {lead}
                            </TextContainer>
                        </div>
                        <div className="relative flex justify-center px-4 py-6 sm:px-6">
                            <img
                                className="rounded-xl"
                                src={image}
                                alt={title}
                            />
                        </div>
                    </div>
                </div>
            </Transition>
        );
    } else {
        return null;
    }
}
