import React from "react";
import NavLink from "./NavLink";

const LogoContent = ({
    fill,
    className = ''
}) => {
    return (
        <>
            <span
            className={`${className} font-sans color-${fill}`}
            >
                Laila schreibt
            </span>
        </>
    )
}


export default function Logo({
    fill = "white",
    link = true,
    className
}) {

    if (link) {
        return (
            <NavLink k="home" key="home" href="">
                <LogoContent
                    fill={fill}
                    className={className}
                />
            </NavLink>
        )
    } else {
        return <LogoContent
            fill={fill}
            className={className}
        />

    }
}