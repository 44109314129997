/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from 'react';
import { Fragment, useContext } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'
import { GlobalContext } from '../context/GlobalState';

import NavLink from './NavLink';
import Logo from './Logo';
import WorkshopButton from './Buttons/Workshop';
import BaseButton from './Buttons/BaseButton';

const mainMenuItems = [
    {
        k: "mein-buch",
        href: "mein-buch",
        label: "Mein Buch",
    },
    {
        k: "projekte",
        href: "projekte",
        label: "Projekte",
    },
    {
        k: "angebot",
        href: "angebot",
        label: "Angebot",
    },
    {
        k: "ueber-mich",
        href: "ueber-mich",
        label: "Über mich",
    },
];

export default function Navigation() {

    return (

        <Popover className="w-full">
            <div className="nav flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10 bg-white">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <Logo />
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                    {
                        mainMenuItems.map(item => (
                            <NavLink
                                key={item.k}
                                k={item.k}
                                href={item.href}
                            >
                                {item.label}
                            </NavLink>
                        ))
                    }
                </Popover.Group>

                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <NavLink
                        key="kontakt"
                        k="kontakt"
                        href="kontakt"
                        classes='text-themeLila border-themeLila no-underline'
                    >
                        <BaseButton>Schreib mir</BaseButton>
                    </NavLink>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Logo fill='theme' />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid grid-cols-1 gap-7">
                                    {mainMenuItems.map((item) => (
                                        <NavLink
                                            key={`m_${item.key}`}
                                            k={`m_${item.key}`}
                                            href={item.href}
                                            classes="text-base font-medium text-gray-900 hover:text-gray-500"
                                        >
                                            {item.label}
                                        </NavLink>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5">
                            <div className="mt-6 flex flex-col justify-center gap-4">
                                <NavLink
                                    key="m_kontakt"
                                    k="m_kontakt"
                                    href="kontakt"
                                    classes="whitespace-nowrap inline-flex items-center justify-center px-5 py-3 border border-solid border-4 rounded-md shadow-sm text-base font-medium text-theme border-4 border-theme bg-white hover:bg-themeLila hover:text-white hover:border-themeHover"
                                >
                                    Schreib mir
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
