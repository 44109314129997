import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 300

const transitionClasses = {
  entering: "absolute opacity-0 translate-y-[100px]",
  entered: `transition-all duration-${timeout} ease-in-out opacity-1 translate-y-0`,
  exiting: `transition-all duration-${timeout} ease-in-out opacity-0`

}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div
              className={transitionClasses[status]}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
