import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import Types from './Types';

const initialState = {
    feedback: null,
    showContact: false,
    showWorkshop: false,
    showOverlay: false,
    selectedItem: null,
    page: null
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    const setFeedback = (feedback) => {
        dispatch({
            type: Types.SET_FEEDBACK,
            payload: feedback
        })
    }

    const setShowContact = (showContact) => {
        dispatch({
            type: Types.SHOW_CONTACT,
            payload: showContact
        })
    }

    const setShowWorkshop = (showWorkshop) => {
        dispatch({
            type: Types.SHOW_WORKSHOP,
            payload: showWorkshop
        })
    }

    const setSelectedItem = (selectedItem) => {
        dispatch({
            type: Types.SET_SELECTED_ITEM,
            payload: selectedItem
        })
    }

    return (
        <GlobalContext.Provider value={{
            feedback: state.feedback,
            showWorkshop: state.showWorkshop,
            showContact: state.showContact,
            selectedItem: state.selectedItem,
            page: state.page,
            setFeedback,
            setShowContact,
            setShowWorkshop,
            setSelectedItem,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}