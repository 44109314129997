import React, { useContext } from "react";
import { GlobalContext } from '../context/GlobalState';


const MainWrapper = ({ children }) => {
    const { howOverlay } = useContext(GlobalContext);

    return (
        <main className={`${howOverlay ? 'overflow-hidden' : ''}`}>
            {children}
        </main>
    )
}

export default MainWrapper
