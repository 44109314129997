import React from "react";


export default function BaseButton({
    children,
    baseColor = "themeLila",
    textColor = "white"
}) {
    return (
        <button className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-${baseColor} shadow-sm text-base font-medium text-${baseColor} transition-all border-2 bg-${textColor} hover:bg-${baseColor} hover:text-${textColor} font-heading`}>
            {children}
        </button>
    )
}