import React, { useRef } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Transition from "../components/transition"
import { GlobalProvider } from "../context/GlobalState"
import ItemOverlay from "../components/ItemOverlay"
import Navigation from "../components/Navigation"
import MainWrapper from "../components/MainWrapper"
import Footer from "../components/Footer"

// import "./layout.css"

const TemplateWrapper = ({ children, location }) => {

    const ref = useRef(null);
    return (
        <div id="home" className="App relative max-w-full pageContent" ref={ref} >
            <GlobalProvider>
                <div className="global-trigger fixed h-0 w-0"></div>
                <ItemOverlay />
                {/* <StickyNavWrapper> */}
                <Navigation />
                {/* </StickyNavWrapper> */}
                <MainWrapper>
                    <Transition location={location}>{children}</Transition>
                </MainWrapper>
                <Footer />
            </GlobalProvider >
        </div >
    )
}

export default TemplateWrapper
