import Types from "./Types";

const AppReducer = (state, action) => {
    switch (action.type) {
        case Types.SET_FEEDBACK:
            return {
                feedback: action.payload
            }

        case Types.SHOW_CONTACT:
            return {
                showContact: action.payload
            }

        case Types.SHOW_WORKSHOP:
            return {
                showWorkshop: action.payload
            }

        case Types.SET_SELECTED_ITEM:
            return {
                selectedItem: action.payload
            }

        case Types.SET_PAGE:
            return {
                page: action.payload
            }

        default:
            return state;
    }
}

export default AppReducer